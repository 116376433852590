<template>
  <el-form ref="form" :inline="true"  :model="form" label-width="120px">
    <div >
      <p>基本信息</p>
      <el-form-item label="客户">
        <el-input v-model="form.customer"></el-input>
      </el-form-item>
      <el-form-item label="合同号">
        <el-input v-model="form.contract"></el-input>
      </el-form-item>
      <el-form-item label="编号">
        <el-input v-model="form.nubmer"></el-input>
      </el-form-item>
      <el-form-item label="标准">
        <el-input v-model="form.standard"></el-input>
      </el-form-item>
      <el-form-item label="化学分析标题">
        <el-input v-model="form.fxtitle"></el-input>
      </el-form-item>
      <el-form-item label="制造许可证号">
        <el-input v-model="form.xgz"></el-input>
      </el-form-item>
      <el-form-item label="检验时间">
        <el-date-picker
            value-format="yyyy:MM:dd HH:mm:ss"
            v-model="form.test_date"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="负责时间">
        <el-date-picker
            value-format="yyyy:MM:dd HH:mm:ss"
            v-model="form.quality_date"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>

    </div>
    <div>
      <el-form-item label="产品型号" style="width: 100%;">
        <el-input type="textarea" style="min-width: 800px;" :rows="2"  v-model="form.cpxh" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="交货状态" style="width: 100%;">
        <el-input type="textarea" style="min-width: 800px;" :rows="2"  v-model="form.jhzt" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="声明" style="width: 100%;">
        <el-input type="textarea" style="min-width: 800px;" :rows="2"  v-model="form.shengming" placeholder=""></el-input>
      </el-form-item>
    </div>
    <div>
      <p>产品性能检验</p>
      <el-form-item label="未知变量1">
        <el-input v-model="form.no_field1"></el-input>
      </el-form-item>
      <el-form-item label="未知变量2">
        <el-input v-model="form.no_field2"></el-input>
      </el-form-item>
    </div>
    <div>
      <p>产品性能检验</p>
      <el-form-item label="卷边实验-标准">
        <el-input v-model="form.ex_p1"></el-input>
      </el-form-item>
      <el-form-item label="卷边实验-结果">
        <el-input v-model="form.ex_pr1"></el-input>
      </el-form-item>
      <el-form-item label="压扁实验-标准">
        <el-input v-model="form.ex_p2"></el-input>
      </el-form-item>
      <el-form-item label="压扁实验-结果">
        <el-input v-model="form.ex_pr2"></el-input>
      </el-form-item>
      <el-form-item label="反向弯曲实验-标准">
        <el-input v-model="form.ex_p3"></el-input>
      </el-form-item>
      <el-form-item label="反向弯曲实验-标题">
        <el-input v-model="form.ex_fx"></el-input>
      </el-form-item>
      <el-form-item label="反向弯曲实验-结果">
        <el-input v-model="form.ex_pr3"></el-input>
      </el-form-item>
      <el-form-item label="扩口实验-标准">
        <el-input v-model="form.ex_p4"></el-input>
      </el-form-item>
      <el-form-item label="扩口实验-结果">
        <el-input v-model="form.ex_pr4"></el-input>
      </el-form-item>
    </div>

    <div>
      <p>产品其它检验</p>
      <el-form-item label="表面质量-依据">
        <el-input v-model="form.other_reportr"></el-input>
      </el-form-item>
      <el-form-item label="表面质量">
        <el-input v-model="form.other_report"></el-input>
      </el-form-item>
      <el-form-item label="外观尺寸-依据">
        <el-input v-model="form.other_exterr"></el-input>
      </el-form-item>
      <el-form-item label="外观尺寸">
        <el-input v-model="form.other_exter"></el-input>
      </el-form-item>
      <el-form-item label="晶间腐蚀-依据">
        <el-input v-model="form.other_intspec"></el-input>
      </el-form-item>
      <el-form-item label="晶间腐蚀-结果">
        <el-input v-model="form.other_intspecr"></el-input>
      </el-form-item>
      <el-form-item label="晶粒度试验-依据">
        <el-input v-model="form.other_graspec"></el-input>
      </el-form-item>
      <el-form-item label="晶粒度试验-结果">
        <el-input v-model="form.other_graspecr"></el-input>
      </el-form-item>
      <el-form-item label="水压试验-依据">
        <el-input v-model="form.other_hydrostatic"></el-input>
      </el-form-item>
      <el-form-item label="水压试验-结果">
        <el-input v-model="form.other_hydrostaticr"></el-input>
      </el-form-item>
      <el-form-item label="水下气压试验-依据">
        <el-input v-model="form.other_underwater"></el-input>
      </el-form-item>
      <el-form-item label="水下气压试验-结果">
        <el-input v-model="form.other_underwaterr"></el-input>
      </el-form-item>
      <el-form-item label="涡流检测-依据">
        <el-input v-model="form.other_eddspec"></el-input>
      </el-form-item>
      <el-form-item label="涡流检测-结果">
        <el-input v-model="form.other_eddspecr"></el-input>
      </el-form-item>
      <el-form-item label="超声检测-依据">
        <el-input v-model="form.other_ultrasonic"></el-input>
      </el-form-item>
      <el-form-item label="超声检测-结果">
        <el-input v-model="form.other_ultrasonicr"></el-input>
      </el-form-item>
      <el-form-item label="X-射线-依据">
        <el-input v-model="form.other_xspec"></el-input>
      </el-form-item>
      <el-form-item label="X-射线-结果">
        <el-input v-model="form.other_xspecr"></el-input>
      </el-form-item>
      <el-form-item label="未知检测">
        <el-input v-model="form.no_check_name"></el-input>
      </el-form-item>
      <el-form-item label="未知检测-依据">
        <el-input v-model="form.no_check_k"></el-input>
      </el-form-item>
      <el-form-item label="未知检测-结果">
        <el-input v-model="form.no_check_r"></el-input>
      </el-form-item>
    </div>
    <el-form-item >
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import axios from "axios";

export default {
  name: "InsView",
  data() {
    return {
      form: {}
    };
  },
  created() {
    this.getBase();
  },
  methods: {
    getBase(){
      let geturl = window.location.href;
      let geturlinfo = geturl.split('?')[1];
      let getqys = new URLSearchParams('?'+geturlinfo);
      let id = getqys.get('id');
      axios.get('/api/Insa/getInsa.html?id='+id).then((res)=>{
        if(res.data.code == 200){
          this.form = res.data.data;
        }

      });
    },
    handleAvatarSuccess(res) {
      let array = Object.values(res);
      this.form.logo = array[0]['file_url'];
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onSubmit() {
      this.axios({
        method:'post',
        url:'/api/Insa/setInsa.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {
          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
        }
      })
    },
  },
};
</script>
<style scoped lang='scss'>
.el-input-number {
  margin-left: 10px;
}
.thisitem{display: block}
</style>